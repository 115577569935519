// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-section {
    background: white;
    padding: 10px 20px;
    border: 1px solid #dddddd;
    border-radius: 3px;
    margin-bottom: 20px;
    width: 60%;
}

.FancyBorder {
    padding: 10px 10%;
    border: 0;
}

.FancyBorder-blue {
    border-color: blue;
}

.content-section:focus {
    transform: scale(1.5);
}

.bg {
    background-size: 100%;
    background-color: rgba(255,255,255,0.2);
    background-blend-mode: lighten;
    padding: 10px;
    min-height: 500px;
}

.middle {
    width: 60%;
    padding: 5px;
    background-color: #e9e9e9;
    margin: 5px 5px 20px !important;
}

.text-left {
    text-indent: 50px;
}

`, "",{"version":3,"sources":["webpack://./src/components/game/posts.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,iBAAiB;IACjB,SAAS;AACb;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;IACrB,uCAAuC;IACvC,8BAA8B;IAC9B,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,yBAAyB;IACzB,+BAA+B;AACnC;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".content-section {\n    background: white;\n    padding: 10px 20px;\n    border: 1px solid #dddddd;\n    border-radius: 3px;\n    margin-bottom: 20px;\n    width: 60%;\n}\n\n.FancyBorder {\n    padding: 10px 10%;\n    border: 0;\n}\n\n.FancyBorder-blue {\n    border-color: blue;\n}\n\n.content-section:focus {\n    transform: scale(1.5);\n}\n\n.bg {\n    background-size: 100%;\n    background-color: rgba(255,255,255,0.2);\n    background-blend-mode: lighten;\n    padding: 10px;\n    min-height: 500px;\n}\n\n.middle {\n    width: 60%;\n    padding: 5px;\n    background-color: #e9e9e9;\n    margin: 5px 5px 20px !important;\n}\n\n.text-left {\n    text-indent: 50px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
