// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.sent .message {
    background-color: #167bff !important;
    color: white !important;
}

.small-gap {
    padding-bottom: 0 !important;
    margin-bottom: 4px !important;
}

`, "",{"version":3,"sources":["webpack://./src/components/game/escovid/text.css"],"names":[],"mappings":";AACA;IACI,oCAAoC;IACpC,uBAAuB;AAC3B;;AAEA;IACI,4BAA4B;IAC5B,6BAA6B;AACjC","sourcesContent":["\n.sent .message {\n    background-color: #167bff !important;\n    color: white !important;\n}\n\n.small-gap {\n    padding-bottom: 0 !important;\n    margin-bottom: 4px !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
