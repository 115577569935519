export { default as Navigation } from "./site/Navigation";
export { default as Home } from "./site/Home";
export { default as Contact } from "./site/Contact";
export { default as About } from "./site/About"
export { default as Final } from "./game/escovid/Final";
export { default as Congrats } from "./game/escovid/congrats";
export { default as Escovid } from "./game/escovid/escovid";
export { default as Leader } from "./game/leaderboard";
export { default as Game } from "./game/Game";
export { default as Post } from "./post";
export { default as TimerNav } from "./game/timerNav"
export {default as Footer} from "./site/Footer"
export {default as Room} from "./site/rooms"




