export { default as Start } from "./start";
export { default as Bakeoff } from "./page1";
export { default as Survivor } from "./page2a";
export { default as Council } from "./page2b";
export { default as Bachelor } from "./page3a";
export { default as AGT } from "./page3b";
export { default as Sofa } from "./page4";
export { default as GIV } from "./page5";
export { default as RealityInstructions} from "./instructions2";
export { default as RemoteButton} from "./remoteButton";


