// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*https://www.bootdey.com/snippets/view/white-chat#comments*/

.chatbox {
    border-width: thick;
    border-color: red;
    min-height: 50vw;
}
.chat-online {
    color: #34ce57
}

.chat-offline {
    color: #e4606d
}

.chat-messages {
    display: flex;
    flex-direction: column;
    max-height: 800px;
    overflow-y: scroll
}

.chat-message-left,
.chat-message-right {
    display: flex;
    flex-shrink: 0;
}

.chat-message-left {
    margin-right: auto
}

.chat-message-right {
    flex-direction: row-reverse;
    margin-left: auto
}
.py-3 {
    padding-top: 1rem!important;
    padding-bottom: 1rem!important;
}
.px-4 {
    padding-right: 1.5rem!important;
    padding-left: 1.5rem!important;
}
.flex-grow-0 {
    flex-grow: 0!important;
}
.border-top {
    border-top: 1px solid #dee2e6!important;
}
.send {
    background-color: #0fd145; /* Green */
    border: none;
    color: white;
    padding: 5px 32px;
    margin: 0px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
}

.send:hover{
    background-color: #fcc81a; /* yellow */
}`, "",{"version":3,"sources":["webpack://./src/components/game/escovid/gform.css"],"names":[],"mappings":"AAAA,4DAA4D;;AAE5D;IACI,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI;AACJ;;AAEA;IACI;AACJ;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,iBAAiB;IACjB;AACJ;;AAEA;;IAEI,aAAa;IACb,cAAc;AAClB;;AAEA;IACI;AACJ;;AAEA;IACI,2BAA2B;IAC3B;AACJ;AACA;IACI,2BAA2B;IAC3B,8BAA8B;AAClC;AACA;IACI,+BAA+B;IAC/B,8BAA8B;AAClC;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,uCAAuC;AAC3C;AACA;IACI,yBAAyB,EAAE,UAAU;IACrC,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,qBAAqB;IACrB,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,yBAAyB,EAAE,WAAW;AAC1C","sourcesContent":["/*https://www.bootdey.com/snippets/view/white-chat#comments*/\n\n.chatbox {\n    border-width: thick;\n    border-color: red;\n    min-height: 50vw;\n}\n.chat-online {\n    color: #34ce57\n}\n\n.chat-offline {\n    color: #e4606d\n}\n\n.chat-messages {\n    display: flex;\n    flex-direction: column;\n    max-height: 800px;\n    overflow-y: scroll\n}\n\n.chat-message-left,\n.chat-message-right {\n    display: flex;\n    flex-shrink: 0;\n}\n\n.chat-message-left {\n    margin-right: auto\n}\n\n.chat-message-right {\n    flex-direction: row-reverse;\n    margin-left: auto\n}\n.py-3 {\n    padding-top: 1rem!important;\n    padding-bottom: 1rem!important;\n}\n.px-4 {\n    padding-right: 1.5rem!important;\n    padding-left: 1.5rem!important;\n}\n.flex-grow-0 {\n    flex-grow: 0!important;\n}\n.border-top {\n    border-top: 1px solid #dee2e6!important;\n}\n.send {\n    background-color: #0fd145; /* Green */\n    border: none;\n    color: white;\n    padding: 5px 32px;\n    margin: 0px 10px;\n    text-align: center;\n    text-decoration: none;\n    display: inline-block;\n    font-size: 16px;\n}\n\n.send:hover{\n    background-color: #fcc81a; /* yellow */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
